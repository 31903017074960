import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import colors_light from '@/assets/styles/export/colors_light.scss';
import colors_dark from '@/assets/styles/export/colors_dark.scss';

Vue.use(Vuetify);

const values = {};
const requireComponent = require.context('@/components/shared/icons', false, /[A-Z]\w+\.(vue)$/);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  const iconName = componentName.split('Icon')[0].toLocaleLowerCase();
  values[iconName] = {
    component: componentConfig.default,
  };
});

export default new Vuetify({
  icons: {
    values,
  },
  theme: {
    themes: {
      light: colors_light,
      dark: colors_dark,
    },
  },
  lang: {
    current: 'pl',
  },
});
