import * as utility from '@/assets/js/utility';
import * as attributesUtility from '@/assets/js/attributesUtility';
import * as mapUtility from '@/assets/js/mapUtility';

const installUtilities = (Vue, utilities) => {
  Object.keys(utilities).forEach(utility => {
    Vue.filter(utility, utilities[utility]);
    Vue.prototype[`$_${utility}`] = utilities[utility];
  });
};

export default {
  install(Vue) {
    installUtilities(Vue, { ...utility, ...attributesUtility, ...mapUtility });
  },
};
