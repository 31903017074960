const state = {
  errors: [],
  successfulls: [],
  messages: [],
};

const mutations = {
  PUSH_MESSAGE: (state, value) => {
    state.messages.push(value);
  },
  PUSH_SUCCESSFULL: (state, value) => {
    state.successfulls.push(value);
  },
  PUSH_ERROR: (state, value) => {
    state.errors.push(value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
