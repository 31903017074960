import Vue from 'vue';
import VueRouter from 'vue-router';

import { beforeAuthenticationRouteEnter, beforeMainRouteEnter } from '@/assets/js/routerUtility';

Vue.use(VueRouter);

const routes = [
  {
    name: 'main',
    path: '',
    component: () => import('@/views/MainView'),
    beforeEnter: (to, from, next) => {
      beforeMainRouteEnter(to, from, next);
    },
  },
  {
    name: 'authentication',
    path: '/authentication',
    component: () => import('@/views/AuthenticationView'),
    beforeEnter: (to, from, next) => {
      beforeAuthenticationRouteEnter(to, from, next);
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
