<template>
  <div class="d-flex justify-center align-center fill-height">
    <v-progress-circular v-bind="{ color: 'primary', ...$attrs }" indeterminate />
  </div>
</template>

<script>
export default {
  name: 'MainLoader',
  inheritAttrs: false,
};
</script>
