<template>
  <v-app id="app" class="fill-height">
    <v-main>
      <template v-if="isFetched">
        <router-view />
      </template>
      <main-loader v-else />
      <the-snackbar />
    </v-main>
  </v-app>
</template>

<script>
import { get } from 'vuex-pathify';

import TheSnackbar from '@/components/shared/TheSnackbar';
import MainLoader from '@/components/shared/MainLoader';

export default {
  name: 'App',
  components: { TheSnackbar, MainLoader },
  data: () => ({
    isFetched: false,
  }),
  computed: {
    basemap: get('tools/basemap'),
    currentLng() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async init() {
      document.title = this.$_config.appName;
      const lng =
        this.$_config.languages.find(language => language === localStorage.getItem('lng')) ||
        this.$_config.languages.find(language => window.navigator.language.startsWith(language)) ||
        this.$_config.defaultLanguage;
      this.$i18n.locale = lng;
      this.$vuetify.lang.current = lng;
      this.$dayjs.locale(lng);
      this.isFetched = true;
    },
  },
  watch: {
    currentLng(nV) {
      if (!nV) return;
      localStorage.setItem('lng', nV);
    },
    basemap: {
      handler(nV) {
        let bodyStyles = document.body.style;
        Object.keys(this.$_colors).forEach(key => {
          bodyStyles.setProperty(`--${key}-color`, this.$_colors[key]);
        });
        this.$vuetify.theme.dark = nV === 'nightlight';
      },
      immediate: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
