import dayjs from 'dayjs';

import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import LocaleData from 'dayjs/plugin/localeData';

const locale = require('dayjs/locale/pl');

dayjs.locale({
  ...locale,
  weekStart: 1,
});

dayjs.extend(LocalizedFormat);
dayjs.extend(LocaleData);

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$dayjs', { value: dayjs });
  },
};
