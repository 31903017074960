import * as variables from '@/assets/js/variables';
import colors_light from '@/assets/styles/export/colors_light.scss';
import colors_dark from '@/assets/styles/export/colors_dark.scss';
import grid from '@/assets/styles/export/grid.scss';

const attachVariables = (Vue, variables, wrappingObject, excludedVariables = []) => {
  const filteredKeys = Object.keys(variables).filter(key => !excludedVariables.includes(key));
  const object = filteredKeys.reduce((total, key) => {
    total[key] = variables[key];
    return total;
  }, {});
  Object.assign(Vue.prototype, { [`$_${wrappingObject}`]: object });
};

export default {
  install(Vue) {
    attachVariables(Vue, variables, 'config');
    attachVariables(Vue, colors_light, 'colorsLight');
    attachVariables(Vue, colors_dark, 'colorsDark');
    attachVariables(Vue, grid, 'grid');
  },
};
