import { make } from 'vuex-pathify';
import { mapDefaultZoom } from '@/assets/js/variables';
import Vue from 'vue';

const state = {
  activeFeature: undefined,
  identifiedFeatures: [],
  sidebarLayersSelected: [],
  zoom: mapDefaultZoom,

  layersToBeChanged: new Set(),
  highlitedFeatures: {},
  selectedFeatures: {},
};

const mutations = {
  ...make.mutations(state),
  ADD_SELECTED_FEATURES: (state, value) => {
    const { features, type } = value;
    const changedLayers = Object.keys(features).map(layerId => {
      Vue.set(state[type], layerId, Array.from(new Set([...(state[type][layerId] || []), ...features[layerId]])));
      return layerId;
    });
    state.layersToBeChanged = new Set([...state.layersToBeChanged, ...changedLayers]);
  },
  DELETE_SELECTED_FEATURES: (state, value) => {
    const { features, type } = value;
    const changedLayers = Object.keys(features)
      .filter(layerId => state[type][layerId])
      .map(layerId => {
        if (features[layerId].length === 0) {
          Vue.delete(state[type], layerId);
        } else {
          features[layerId].forEach(layer => {
            Vue.delete(state[type][layerId], state[type][layerId].indexOf(layer));
          });
        }
        return layerId;
      });
    state.layersToBeChanged = new Set([...state.layersToBeChanged, ...changedLayers]);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
