import dayjs from 'dayjs';
import i18n from '@/plugins/i18n';

const getForm = (elements, metadata, filteredOutAttributes = []) => {
  return elements
    .filter(
      element =>
        (element.element_type === 'group' && element.elements.length > 0) ||
        (element.element_type === 'attribute_holder' && !filteredOutAttributes.includes(element.attribute))
    )
    .map(element => {
      if (element.element_type === 'group') {
        return {
          ...element,
          elements: getForm(element.elements, metadata, filteredOutAttributes),
        };
      }
      const { data_type: dataType, measurement_unit: unit } =
        metadata.find(attr => attr.name === element.attribute) || {};
      return {
        ...element,
        dataType,
        icon: dataType?.name === 'datetime' ? 'mdi-calendar' : '',
        unit: unit?.symbol || null,
      };
    });
};

const getValues = (formSchema, values = {}, { emptyValue = null } = {}) => {
  return formSchema.reduce((total, element) => {
    if (element.element_type === 'group') {
      return { ...total, ...getValues(element.elements, values, { emptyValue }) };
    }
    return {
      ...total,
      [element.attribute]: getValue(element, values, { emptyValue }),
    };
  }, {});
};

const getValue = (attribute, values = {}, { emptyValue = null } = {}) => {
  let value = emptyValue;
  if (values[attribute.attribute] || values[attribute.attribute] === false || values[attribute.attribute] === 0) {
    value = values[attribute.attribute];
    const dataTypeName = attribute.dataType.name;
    if (dataTypeName === 'datetime') {
      return dayjs(value).format('L, LT');
    } else if (dataTypeName === 'date') {
      return value ? this.$dayjs(value).format('L') : emptyValue;
    } else if (dataTypeName === 'boolean') {
      return value ? i18n.t('default.yes') : i18n.t('default.no');
    }
  }
  return value;
};

export { getForm, getValues };
