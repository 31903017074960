import Vue from 'vue';
import { make } from 'vuex-pathify';
import { arrayToObject } from '@/assets/js/utility';

import api from '@/services/api';

const state = {
  currentUser: undefined,
  permissions: {},
  token:
    localStorage.getItem('token') && localStorage.getItem('token') !== 'null' ? localStorage.getItem('token') : null,
};

const mutations = {
  ...make.mutations(state),
  SET_PERMISSIONS(state, value) {
    const { layers } = value;
    Vue.set(state.permissions, 'layers', Object.freeze(arrayToObject(layers, 'layer_id')));
  },
  SET_TOKEN(state, value) {
    state.token = value;
    if (value) localStorage.setItem('token', value);
    else localStorage.removeItem('token');
  },
};

const actions = {
  async checkToken({ commit }) {
    try {
      await api.get('check_token');
      const r = await api.get('current_user');
      commit('SET_CURRENT_USER', r.data.data);
      return true;
    } catch {
      commit('SET_TOKEN', null);
      return false;
    }
  },
  async getPermissions({ commit }) {
    const r = await api.get('layers/permissions');
    commit('SET_PERMISSIONS', r.data.data);
  },
  async login({ commit }, payload) {
    const r = await api.post('login', payload);
    commit('SET_TOKEN', r.data.token);
  },
  async logout({ commit }) {
    await api.get('logout');
    commit('SET_TOKEN', null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
