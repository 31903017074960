import { Point, LineString, Polygon } from 'ol/geom';
import { getCenter } from 'ol/extent';
import { GeoJSON } from 'ol/format';
import { mapDataCoordinateSystem } from '@/assets/js/variables';

const getGeometryCentroid = geometry => {
  if (geometry instanceof Point) {
    return geometry.getCoordinates();
  } else if (geometry instanceof LineString) {
    return geometry.getCoordinateAt(0.5);
  } else if (geometry instanceof Polygon) {
    return geometry.getInteriorPoint().getCoordinates().splice(0, 2);
  } else {
    return getCenter(geometry.getExtent());
  }
};

const getGeojsonByOlGeometry = feature => {
  const geoJsonFeature = new GeoJSON().writeFeatureObject(feature, {
    featureProjection: mapDataCoordinateSystem?.epsg,
    dataProjection: mapDataCoordinateSystem?.epsg,
  });
  return {
    ...geoJsonFeature.geometry,
    crs: {
      type: 'name',
      properties: {
        name: mapDataCoordinateSystem?.epsg,
      },
    },
  };
};

export { getGeometryCentroid, getGeojsonByOlGeometry };
