import Vue from 'vue';
import api from '@/services/api';
import { make } from 'vuex-pathify';
import { saveFileFromBackend } from '@/assets/js/utility';

const state = {
  activeTool: '',
  activeMapDialogs: [],
  basemap: localStorage.getItem('basemap') || 'daylight',
  filtersItems: { district: {}, street: [] },
  filtersValues: {},
  identificationCols: 0,
  isSidebarVisible: true,
  printsParams: undefined,
  printsPreview: {
    center: [0, 0],
    extent: [0, 0, 0, 0],
  },
  project: { id: null, layers: [], name: '' },
  selectedLuminareSymbolizationLayer: null,
  selectedMap: null,
};

const mutations = {
  ...make.mutations(state),
  SET_LAYER_VISIBILITY: (state, { layersIds = [], value = false } = {}) => {
    layersIds.forEach(layerId => {
      const layer = state.project.layers.find(l => l.layer_id === layerId);
      if (layer) Vue.set(layer, 'visible', value);
      else {
        state.project.layers
          .filter(element => element.type === 'group')
          .forEach(group => {
            const groupLayer = group.layers.find(l => l.layer_id === layerId);
            if (groupLayer) Vue.set(groupLayer, 'visible', value);
          });
      }
    });
  },
  SET_ACTIVE_MAP_DIALOG(state, { data, value }) {
    if (value) {
      Vue.set(state, 'activeMapDialogs', [
        ...state.activeMapDialogs.filter(item => {
          return (
            item.name !== data.name &&
            (data.allowOtherTop || item.position !== 'top') &&
            (data.allowOtherLeft || item.position !== 'left')
          );
        }),
        data,
      ]);
    } else {
      Vue.set(
        state,
        'activeMapDialogs',
        state.activeMapDialogs.filter(item => item.name !== data.name)
      );
    }
  },
};

const actions = {
  async getFiltersUniquesValues({ commit }) {
    const r = await api.get('attributes/unique_values');
    const streetsSet = Object.values(r.data.data).reduce((total, current) => {
      return new Set([...total, ...current]);
    }, new Set());
    const attributesValues = {
      district: r.data.data,
      street: Array.from(streetsSet),
    };
    commit('SET_FILTERS_ITEMS', Object.freeze(attributesValues));
  },
  async print(state, { payload, fileName = 'print.pdf' }) {
    const r = await api.post('print', payload, {
      noWrap: true,
      responseType: 'arraybuffer',
    });
    saveFileFromBackend(r.data, r.headers, fileName);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
