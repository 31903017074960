import store from '@/store';

const beforeMainRouteEnter = async (to, from, next) => {
  if (store.get('authentication/token')) await store.dispatch('authentication/checkToken');
  next();
};

const beforeAuthenticationRouteEnter = async (to, from, next) => {
  if (store.get('authentication/token')) {
    const isTokenValid = await store.dispatch('authentication/checkToken');
    if (isTokenValid) next({ name: 'main' });
    return;
  }
  next();
};

export { beforeAuthenticationRouteEnter, beforeMainRouteEnter };
