const mapDataCoordinateSystem = {
  center: [1022537.013808023, 6237858.975443388],
  epsg: 'EPSG:3857',
  srid: 3857,
  verboseName: 'Google Mercator',
};

const mapDefaultZoom = 12;
const mapDefaultMinZoom = 0;
const mapDefaultMaxZoom = 28;

const appName = 'ILS Easy';
const defaultLanguage = 'en';
const languages = ['pl', 'en', 'de'];

const maxIdentifiedFeatues = 7;
const identificationSteps = [0, 4, 6, 10];
const identificationColumns = 3;

const printsSrid = 2180;
const availableFormPrints = {
  masterplan_2_illuminations: 'masterplan_illuminations',
  masterplan_2_squares: 'masterplan_squares',
  masterplan_2_streets: 'masterplan_streets',
};

export {
  mapDataCoordinateSystem,
  mapDefaultZoom,
  mapDefaultMinZoom,
  mapDefaultMaxZoom,
  appName,
  defaultLanguage,
  languages,
  maxIdentifiedFeatues,
  identificationSteps,
  identificationColumns,
  printsSrid,
  availableFormPrints,
};
